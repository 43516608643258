<template>
    <div class="hiq-events-list__search-results--hits__hit" :class="swiper ? ' swiper' : ''">            
        <a :href="landingPage ? landingPage + '?id=' + hit.id : hit.url" class="hiq-events-list__search-results--hits__hit--link" :title="hit.title" :style="accentColor">
            <div class="hiq-events-list__search-results--hits__hit--image__wrapper">
                <img class="lazyload hiq-events-list__search-results--hits__hit--image opacity-lazy" data-expand="-10" :data-src="hit.image.mediumSrc || hit.image.src" :alt="hit.image.alt" />
            </div>
            <div class="hiq-events-list__search-results--hits__hit--short-date" :style="backgroundAndTextColor" v-html="getShortDate(hit.info.start, hit.info.end)"></div>
            <div class="hiq-events-list__search-results--hits__hit--text-content">
                <div class="hiq-events-list__search-results--hits__hit--header" :class="isVisitSite ? 'visit' : ''" :style="textColor">
                    {{hit.title}}
                </div>
                <div class="hiq-events-list__search-results--hits__hit--info" :style="textColor">
                    <span class="hiq-events-list__search-results--hits__hit--info__date" :style="textColor">{{getTime(hit.info.start)}}<span :style="accentColor" aria-hidden="true" class="hiq-events-list__search-results--hits__hit--info__date--icon"></span></span>
                    <span class="hiq-events-list__search-results--hits__hit--info__location" :style="textColor">{{hit.info.location.name}}<span :style="accentColor" aria-hidden="true" class="hiq-events-list__search-results--hits__hit--info__location--icon"></span></span>
                </div>             
            </div>        
        </a>
    </div>

</template>

<script>
import Vue from 'vue';
import moment from 'moment';

var locale = document.documentElement.lang;
moment.locale(locale);

export default {
    name: 'Event',
    props: {
        hit: Object,
        swiper: Boolean,
    },
    data() {
        return {
            
        }
    },
    methods: {
        getTime(dateString){
            return moment(dateString).format('HH.mm');
        },
        getShortDate(startDate, endDate){
            var formattedDate = '';
            startDate = moment(startDate);
            endDate = moment(endDate);
            formattedDate = startDate.format('DD MMM');
            if(!startDate.isSame(endDate, 'day')){
                formattedDate += ' - ' + endDate.format('DD MMM');
            }
            return formattedDate;
        },
    },
    computed: {
        textColor() { return this.$store.getters.textColor; },
        accentColor() { return this.$store.getters.accentColor; },
        accentBackgroundColor() { return this.$store.getters.accentBackgroundColor; },
        landingPage() { return this.$store.state.landingPage },
        isVisitSite() { return this.$store.state.isVisitSite },
        backgroundAndTextColor() {
            var styleString = this.$store.getters.accentBackgroundColor;
            if(this.$store.state.isVisitSite){
                styleString += ' color: #FFFFFF';
            } else {
                styleString += ' color: #000';
            }
            return styleString;
        },
    }
}
</script>