<template>
    <div class="hiq-events-list__search-results" aria-live="polite">
        <ul v-if="hits && hits.length > 0 && !useSwipe" class="hiq-events-list__search-results--hits">
            <event v-for="hit in hits" :hit="hit" :key="hit.id" :swiper="false"/>
        </ul>
        <div v-else-if="hits && hits.length > 0 && useSwipe" class="hiq-events-list__swiper--wrapper">
            <swiper :options="swiperOption">
                <swiper-slide v-for="hit in hits" :key="hit.id">
                    <event :hit="hit" :swiper="true"/>
                </swiper-slide>
                <swiper-slide v-if="showAllPageLink && totalHits > count">
                    <div class="hiq-events-list__swiper--show-all" :style="accentBackgroundColor">
                        <a class="hiq-events-list__swiper--show-all__link" :href="showAllPageLink" :style="isVisitSite ? 'color: #FFF;' : 'color: #000;'">
                            <span class="hiq-events-list__swiper--show-all__link--icon" aria-hidden="true"></span>
                            <span class="hiq-events-list__swiper--show-all__link--text">Visa alla<span class="sv-visuallyhidden"> evenemang som är taggade med {{heading}}</span></span>
                        </a>
                    </div>
                </swiper-slide>
                <div class="hiq-events-list__swiper--pagination swiper-pagination" :style="accentColor" slot="pagination"></div>
                <div role="button" :style="accentColor" aria-label="Föregående sida" class="hiq-events-list__swiper--button hiq-events-list__swiper--button-prev swiper-button-prev" slot="button-prev"></div>
                <div role="button" :style="accentColor" aria-label="Nästa sida" class="hiq-events-list__swiper--button hiq-events-list__swiper--button-next swiper-button-next" slot="button-next"></div>
            </swiper>
        </div>
        <p v-else-if="!hits || hits.length === 0" class="hiq-events-list__search-results--no-hits" :style="isVisitSite ? 'color: #000;' : 'color: #FFF;'">{{noEventsText}}</p>
    </div>
</template>

<script>
import Vue from 'vue';
import Event from './Event.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'


export default {
    name: 'EventList',
    data() {
        return {
            swiperOption: {
                slidesPerView: 2,
                slidesPerGroup: 2,
                loop: false,
                pagination: {
                    el: '.hiq-events-list__swiper--pagination',
                    type: 'bullets',
                    clickable: true,
                    bulletClass: 'hiq-events-list__swiper--pagination__bullet',
                    bulletActiveClass: 'active',
                    renderBullet: this.renderBullet,
                },
                navigation: {
                    nextEl: '.hiq-events-list__swiper--button-next',
                    prevEl: '.hiq-events-list__swiper--button-prev'
                },
                breakpoints: {
                    640: {
                        slidesPerGroup: 4,
                        slidesPerView: 4,
                    },
                }
            }
        }
    },
    computed: {
        heading() { return this.$store.getters.heading; },
        noEventsText() { return this.$store.state.noEventsText },
        hits(){ return this.$store.getters.events; },
        useSwipe(){ return this.$store.getters.useSwipe; },
        accentColor() { return this.$store.getters.accentColor; },
        accentBackgroundColor() { return this.$store.getters.accentBackgroundColor; },
        showAllPage() { return this.$store.getters.showAllPage; },
        showAllPageLink() { return this.$store.getters.showAllPageLink; },
        totalHits() { return this.$store.getters.totalHits; },
        count() { return this.$store.getters.count; },
        isVisitSite() { return this.$store.state.isVisitSite; }
    },
    methods: {
        renderBullet(index, className){
            var borderString = 'border-color:';
            if(this.isVisitSite){
                borderString += this.$store.state.accentColor;
            } else {
                borderString += '#000';
            }
            return '<span aria-label="Gå till slide '+ (index + 1) +'" style="'+this.accentBackgroundColor+'; '+borderString+';" class="'+className+'"></span>';
        },
    },
    components: {
        Event,
        Swiper,
        SwiperSlide
    },
}
</script>